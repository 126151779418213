import {i18n, useTranslation} from 'next-i18next'
import React, {useState} from 'react'
import {DeliveryPointInfoResponse} from 'types/api-types'

type ServicePointDetailFuelProductsProps = {
  extendedDataPoint: DeliveryPointInfoResponse['serviceDeliveryPoint'] | null
}

const ServicePointDetailFuelProducts = ({
  extendedDataPoint,
}: ServicePointDetailFuelProductsProps) => {
  const {t} = useTranslation()

  const [expandedFuelType, setExpandedFuelType] = useState<string | null>(null)

  const fuelsInfo = extendedDataPoint?.fuelStation.products.filter(
    product => product.code !== 'B',
  )

  const flatTireStation = extendedDataPoint?.fuelStation.products.find(
    product => product.code === 'B',
  )

  const expandOrCollapseFuelType = (connectorType: string) => {
    if (expandedFuelType === connectorType) {
      setExpandedFuelType(null)
    } else {
      setExpandedFuelType(connectorType)
    }
  }

  const constructOfficialPriceLabel = (officialPrice?: string) => {
    if (!officialPrice) {
      return <></>
    }

    return (
      <p>
        {t('label_service_point_locator_official_price')} {officialPrice} EUR/l
      </p>
    )
  }

  const constructOfficialPriceDiscountLabel = (
    officialPrice?: string,
    actualPrice?: string,
  ) => {
    if (!officialPrice) {
      return <></>
    }

    const numberOfficialPrice = Number(officialPrice)
    const numberActualPrice = Number(actualPrice)
    const discountPrice = numberOfficialPrice - numberActualPrice

    return (
      <p>
        {t('label_service_point_locator_your_discount')}{' '}
        {discountPrice.toFixed(3)} EUR/l
      </p>
    )
  }

  return (
    <div>
      <div>
        <div className="bg-dats-s10 p-4 font-rubik text-base font-bold">
          {t('label_service_point_locator_details_pump_prices')}
        </div>
        <div className="[&>div]:flex [&>div]:justify-between [&>div]:p-4 [&>div]:pb-2 [&>div]:font-rubik [&>div]:text-sm">
          {fuelsInfo?.map(fuelInfo => (
            <div key={fuelInfo.code} className="flex flex-col justify-between">
              <div className="flex justify-between">
                <span>
                  {i18n?.language === 'nl'
                    ? fuelInfo.nameDutch
                    : fuelInfo.nameFrench}
                </span>
                <span>{`${fuelInfo?.priceEuro.slice(0, -1)} ${
                  fuelInfo?.currency
                }/${
                  fuelInfo.unit.toLowerCase() === 'liter' ? 'l' : 'kg'
                }`}</span>
              </div>
              {fuelInfo.officialPriceEuro ? (
                <div>
                  <button
                    onClick={() => expandOrCollapseFuelType(fuelInfo.code)}
                    className="flex items-center justify-start space-x-2"
                  >
                    <div className="font-sans text-[12px] font-semibold text-dats-s3">
                      {t('label_service_point_locator_price_info')}
                    </div>

                    {expandedFuelType === fuelInfo.code ? (
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.07767 6.43137C5.36665 6.72419 5.83595 6.72419 6.12493 6.43137L10.5636 1.93363C10.8526 1.64081 10.8526 1.16527 10.5636 0.872448C10.2747 0.579627 9.80536 0.579627 9.51638 0.872448L5.60015 4.84076L1.68391 0.87479C1.39493 0.581969 0.925634 0.581969 0.636656 0.87479C0.347677 1.16761 0.347677 1.64315 0.636656 1.93598L5.07536 6.43371L5.07767 6.43137Z"
                          fill="#005E75"
                          transform="scale(1, -1) translate(0, -7)"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.07767 6.43137C5.36665 6.72419 5.83595 6.72419 6.12493 6.43137L10.5636 1.93363C10.8526 1.64081 10.8526 1.16527 10.5636 0.872448C10.2747 0.579627 9.80536 0.579627 9.51638 0.872448L5.60015 4.84076L1.68391 0.87479C1.39493 0.581969 0.925634 0.581969 0.636656 0.87479C0.347677 1.16761 0.347677 1.64315 0.636656 1.93598L5.07536 6.43371L5.07767 6.43137Z"
                          fill="#005E75"
                        />
                      </svg>
                    )}
                  </button>
                  {expandedFuelType === fuelInfo.code ? (
                    <div className="mb-4 flex flex-col space-y-2">
                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-border-s2"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructOfficialPriceLabel(
                            fuelInfo.officialPriceEuro,
                          )}
                        </span>
                      </div>

                      <div className="flex items-center justify-start space-x-2">
                        <div className="size-2 rounded-full bg-dats-border-s2"></div>
                        <span className="font-sans text-[12px] text-dats-neutral-darkest">
                          {constructOfficialPriceDiscountLabel(
                            fuelInfo.officialPriceEuro,
                            fuelInfo.priceEuro,
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className="bg-dats-s10 p-4 font-rubik text-base font-bold">
        {t('label_service_point_locator_details_facilities')}
      </div>
      <div className="[&>div]:flex [&>div]:justify-between   [&>div]:p-4 [&>div]:font-rubik [&>div]:text-sm">
        {flatTireStation ? (
          <div className="flex justify-between">
            <span>
              {i18n?.language === 'nl'
                ? flatTireStation.nameDutch
                : flatTireStation.nameFrench}
            </span>
            <span className="font-body font-semibold text-dats-success-green first-letter:uppercase">
              {t('label_service_point_locator_free')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ServicePointDetailFuelProducts
